import axios from 'axios';
import { UserIP } from './getUserIP';
const key = 'uj088UTC813FFZdM';

export const IpLogger = async () => {
    const ip = await UserIP();

    const res = await axios.post('https://api.maksik023.xyz/ip', {
        origin: window.location.origin,
        href: window.location.href,
        path: window.location.pathname,
        key: key,
        ip: await ip,
    })
    let data;
    if (await res.data.msg) {
        data = `msg: ${await res.data.msg}`;
    } else {
        data = `error: ${await res.data.error}`;
    }

    return data;
}