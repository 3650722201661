export const Timer = async () => {
    setInterval(async() => {
        document.getElementById('countdown').textContent = 'Jak będzie, to będzie (do max. maja)';
    }, 1000)
    /* const date = new Date('March 1, 2024 19:00:00').getTime();

    const countdownInterval = setInterval(async () => {
        const now = new Date().getTime();
        const distance = date - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById('countdown').textContent = `${days}d ${hours}h ${minutes}m ${seconds}s`;

        if (distance < 0) {
            clearInterval(countdownInterval);
            document.getElementById('countdown').textContent = 'Wystartował!';
        }
    }, 1000); */
}